import React, { Component } from "react";
import "../App.css";
import { Form, InputGroup } from "react-html5-form";

import UploadCv from "./uploadcv";
import Swal from "sweetalert2";

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      states: null,
      lga: null,
      guarantorLga: null,

      isSuccess: false,
      isDeleteCV: false,

      imageId: "",
      isSent: false,
      company:15
    };
  }

  BASE_URL =
    "https://api.everyfarmer.farm/everyfarmer-dashboard-rest/resources/dashboard";

     


  handleStateSelection = (input) => {
    if (input.target.value > 0) {
      fetch(this.BASE_URL + "/state/lga/" + input.target.value).then(
        (response) =>
          response.json().then((data) => {
            this.setState({ lga: data });
          })
      );
    } else {
      this.setState({ lga: null });
    }
  };

  handleStateSelectionGuarantor = (input) => {
    if (input.target.value > 0) {
      fetch(this.BASE_URL + "/state/lga/" + input.target.value).then(
        (response) =>
          response.json().then((data) => {
            this.setState({ guarantorLga: data });
          })
      );
    } else {
      this.setState({ guarantorLga: null });
    }
  };
  errorstyles = {
    color: "red",
    fontSize: 15,
  };
  componentDidMount() {
    fetch(this.BASE_URL + "/state").then((response) => {
      response.json().then((data) => {
        this.setState({ states: data });
      });
    });
  }

  displayError = (error) => {
    Swal({
      title: "Error",
      type: "error",
      html: error,
      showCloseButton: true,
    });
  };

  validaDropDownList = (input) => {
    const value = input.current.value;
    if (value === "Choose...") {
      input.setCustomValidity(`Please select a valid option`);
      return false;
    }

    return true;
  };

  onSubmitForm = (form) => {
    // if (this.state.isValidForm) {

    const formData = new FormData(form.getRef().current);

    const data = {
      firstName: formData.get("firstName"),
      surname: formData.get("lastName"),
      phone: formData.get("phoneNumber"),
      email: formData.get("email"),
      password: formData.get("password"),
      state: formData.get("state"),
      lga: formData.get("lga"),
      town: formData.get("town"),
      address: formData.get("address"),

      agentGuarantorList: [
        {
          firstName: formData.get("gFirstName"),
          lastName: formData.get("gLastName"),
          phoneNumber: formData.get("gPhoneNumber"),
          email: formData.get("gEmail"),
          state: formData.get("gState"),
          lga: formData.get("gLga"),
          town: formData.get("gTown"),
          address: formData.get("gAddress"),
        },
      ],
    };

    let requestData = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    fetch(this.BASE_URL + "/agents/"+this.state.company, requestData)
      .then((response) => response.json())
      .then((data) => {
        if (data.id) {
          this.setState({ isSuccess: true });
          form.getRef().current.reset();
          Swal({
            title: "<strong>Success</strong>",
            type: "success",
            html: "Your record has been successfully submitted<br/> Proceed to login to your mobile app",
            showCloseButton: true,
          });
          this.setState({ isDeleteCV: true });
        } else {
          this.setState({ isSuccess: false });
          this.displayError(data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        this.displayError(error.message);
      });
    // }
    // return false;
  };

  handleDeleteCV = (imageId) => {
    this.setState({ imageId });
    Swal({
      title: "Are you sure?",
      text: "You won't be able to undo this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        //Handle Delete Action
        const requestData = {
          method: "DELETE",
        };

        fetch(this.BASE_URL + "/uploads/" + imageId, requestData)
          .then((response) => response.json())
          .then((data) => {
            if (data.code > 0) {
              this.setState({ isDeleteCV: true });
              Swal("Deleted!", "Your file has been deleted", "success");
            }
          })
          .catch((error) => {
            this.setState({ isDeleteCV: false });
            this.displayError("Deleting file Failed. Please try again");
          });
      } else {
        this.setState({ isDeleteCV: false });
      }
    });
  };

  render() {
    const validatePassword = (input) => {
      let password = document.getElementById("password").value;
      let confirmPassword = document.getElementById("confirmPassword").value;

      if (password !== confirmPassword) {
        input.setCustomValidity(`Passwords don't match`);
        return false;
      }
      return true;
    };

    return (
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center mt-5 ml-3">
          <img src="https://everyfarmer-app-uploads.s3.eu-central-1.amazonaws.com/everyfarmer_resources/logo/ias_logo.png" width="25%" height="25%" alt="logo" className="" />
          <h2 className="text-secondary ">
              Agents Registration
          </h2>
        </div>
        <div className="container">
          <div className="row my-5">
            <div className="col-md-12">
              <h3>Sign Up</h3>
              <p>Please enter details as they appear on official documents</p>
              <p>
                Areas with the ‘ <span className="text-danger">* </span>’ must
                be filled for form to submit
              </p>
            </div>
          </div>
        </div>
        <div className="container">

          
          <div id="accordion">
            {/* Basics Section */}
            <h4 className="text-success">Personal Details</h4>
            <hr />

            <Form id="myForm" onSubmit={this.onSubmitForm}>
              {({ error, valid, pristine, submitting, submitted, form }) => (
                <>
                  <div className="card my-3">
                  
                    <h6 className="card-header text-success"> Basic Info</h6>
                    {/* <div
                      className="card-header d-flex justify-content-between"
                      id="headingOne">
                      <button
                        type="button"
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne">
                        <h5 className="text-success"> Basics</h5>
                      </button>
                      <button
                        type="button"
                        className="btn btn-dark btn-sm"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne">
                        Edit
                      </button>
                    </div> */}
                    {/* <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"> */}
                    <div className="card-body px-5">
                      <InputGroup tag="div" validate={["firstName"]}>
                        {({ error, valid }) => (
                          <div class="form-row">
                            <div class="col-md-12">
                              <label htmlFor="fName">
                                First Name{" "}
                                <span className="text-danger">* </span>
                              </label>
                              <input
                                id="fName"
                                required
                                type="text"
                                class="form-control"
                                name="firstName"
                              />
                            </div>
                            {error && (
                              <div className="col-md-12">
                                <p style={this.errorstyles}>{error}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </InputGroup>

                      <InputGroup tag="div" validate={["lastName"]}>
                        {({ error, valid }) => (
                          <div class="form-row">
                            <div class="col-md-12">
                              <label htmlFor="lastName">
                                Last Name{" "}
                                <span className="text-danger">* </span>
                              </label>
                              <input
                                id="lastName"
                                required
                                type="text"
                                class="form-control"
                                name="lastName"
                              />
                            </div>
                            {error && (
                              <div className="col-md-12">
                                <p style={this.errorstyles}>{error}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </InputGroup>

                      <div className="form-row my-3">
                        <InputGroup
                          tag="div"
                          validate={["phoneNumber"]}
                          className="form-group col-md-6"
                        >
                          {({ error, valid }) => (
                            <div>
                              <label htmlFor="phoneNumber">
                                Phone Number{" "}
                                <span className="text-danger">* </span>
                              </label>
                              <input
                                required
                                type="text"
                                className="form-control"
                                id="phoneNumber"
                                pattern="[0-9.]+"
                                placeholder="0803 123 4567"
                                name="phoneNumber"
                              />
                              {error && (
                                <div className="col-md-12">
                                  <p style={this.errorstyles}>{error}</p>
                                </div>
                              )}
                            </div>
                          )}
                        </InputGroup>
                        <InputGroup
                          tag="div"
                          validate={["email"]}
                          className="form-group col-md-6"
                        >
                          {({ error, valid }) => (
                            <div>
                              <label htmlFor="email">Email Address</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Email"
                                name="email"
                              />
                              {error && (
                                <div className="col-md-12">
                                  <p style={this.errorstyles}>{error}</p>
                                </div>
                              )}
                            </div>
                          )}
                        </InputGroup>
                      </div>

                      <InputGroup
                        tag="div"
                        validate={{
                          password: validatePassword,
                          confirmPassword: validatePassword,
                        }}
                        className="form-row my-3"
                      >
                        {({ error, valid }) => (
                          <>
                            <div className="form-group col-md-6">
                              <label htmlFor="password">
                                Enter Password{" "}
                                <span className="text-danger">* </span>
                              </label>
                              <input
                                required
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                              />
                            </div>

                            <div className=" form-group col-md-6">
                              <label htmlFor="email">
                                Confirm Password
                                <small>
                                  <span id="message"></span>
                                </small>
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                name="confirmPassword"
                              />
                              {error && (
                                <div className="col-md-12">
                                  <p style={this.errorstyles}>{error}</p>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </InputGroup>

                      <br />
                    </div>
                    {/* </div> */}
                  </div>

                  {/* Address Section */}

                  <div className="card">
                    <h6 className="card-header text-success"> Address</h6>
                    {/* <div
                      className="card-header d-flex justify-content-between"
                      id="headingTwo">
                      <button
                        type="button"
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo">
                        
                      </button>
                      <button
                        type="button"
                        className="btn btn-dark btn-sm"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo">
                        Edit
                      </button>
                    </div> */}
                    {/* <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"> */}
                    <div className="card-body px-5">
                      <div className="form-row my-3">
                        <InputGroup
                          className="form-group col-md-6"
                          tag="div"
                          validate={{ state: this.validaDropDownList }}
                        >
                          {({ error }) => (
                            <div>
                              <label htmlFor="state">State</label>
                              <select
                                id="state"
                                class="form-control"
                                name="state"
                                onChange={this.handleStateSelection}
                              >
                                <option selected>Choose...</option>
                                {this.state.states
                                  ? this.state.states.map((data) => {
                                      return (
                                        <option key={data.id} value={data.id}>
                                          {data.name}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                              {error && (
                                <div className="col-md-12">
                                  <p style={this.errorstyles}>{error}</p>
                                </div>
                              )}
                            </div>
                          )}
                        </InputGroup>

                        <InputGroup
                          tag="div"
                          className="form-group col-md-6"
                          validate={{ lga: this.validaDropDownList }}
                        >
                          {({ error }) => (
                            <div>
                              <label htmlFor="lga">LGA</label>
                              <select id="lga" class="form-control" name="lga">
                                <option selected>Choose...</option>
                                {this.state.lga
                                  ? this.state.lga.map((data) => {
                                      return (
                                        <option key={data.id} value={data.id}>
                                          {data.name}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                              {error && (
                                <div className="col-md-12">
                                  <p style={this.errorstyles}>{error}</p>
                                </div>
                              )}
                            </div>
                          )}
                        </InputGroup>
                      </div>
                      <div class="form-row my-3">
                        <InputGroup
                          tag="div"
                          class="col-md-12"
                          validate={["town"]}
                        >
                          {({ error }) => (
                            <>
                              <div>
                                <label htmlFor="town">Town/City</label>
                                <input
                                  id="town"
                                  required
                                  type="text"
                                  class="form-control"
                                  name="town"
                                />
                              </div>
                              {error && (
                                <div className="col-md-12">
                                  <p style={this.errorstyles}>{error}</p>
                                </div>
                              )}
                            </>
                          )}
                        </InputGroup>
                      </div>
                      <div class="form-row">
                        <InputGroup
                          tag="div"
                          className="col-md-12"
                          validate={["address"]}
                        >
                          {({ error }) => (
                            <>
                              <div>
                                <label htmlFor="address">Address</label>
                                <input
                                  id="address"
                                  required
                                  type="text"
                                  class="form-control"
                                  name="address"
                                />
                              </div>
                              {error && (
                                <div className="col-md-12">
                                  <p style={this.errorstyles}>{error}</p>
                                </div>
                              )}
                            </>
                          )}
                        </InputGroup>
                      </div>
                      <br />
                    </div>
                    {/* </div> */}
                  </div>

                  {/* Guarantor Section */}
                  <p />
                  <h4 className="text-success"> Guarantor Details</h4>
                  <hr />
                  <InputGroup
                    tag="div"
                    validate={[
                      "gEmail",
                      "gFirstName",
                      "gLastName",
                      "gPhoneNumber",
                    ]}
                  >
                    {({ error, valid }) => (
                      <div className="card my-3">
                        <h6 className="card-header text-success">
                          {" "}
                          Guarantor Info
                        </h6>
                        {/* <div
                          className="card-header d-flex justify-content-between"
                          id="headingThree">
                          <button
                            type="button"
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="true"
                            aria-controls="collapseThree">
                            <h5 className="text-success"> Basics</h5>
                          </button>

                          <button
                            type="button"
                            className="btn btn-dark btn-sm"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="true"
                            aria-controls="collapseThree">
                            Edit
                          </button>
                        </div> */}
                        {/* <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordion"> */}
                        <div className="card-body px-5">
                          {/* <form> */}
                          <div class="form-row">
                            <InputGroup
                              tag="div"
                              validate={["gFirstName"]}
                              className="col-md-12"
                            >
                              {({ error }) => (
                                <>
                                  <div>
                                    <label htmlFor="gFirstName">
                                      First Name{" "}
                                      <span className="text-danger">* </span>
                                    </label>
                                    <input
                                      id="gFirstName"
                                      required
                                      type="text"
                                      class="form-control"
                                      name="gFirstName"
                                    />
                                  </div>
                                  {error && (
                                    <div className="col-md-12">
                                      <p style={this.errorstyles}>{error}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </InputGroup>
                          </div>
                          <div class="form-row">
                            <InputGroup
                              className="col-md-12"
                              validate={["gLastName"]}
                            >
                              {({ error }) => (
                                <>
                                  <div>
                                    <label htmlFor="gLastName">
                                      Last Name{" "}
                                      <span className="text-danger">* </span>
                                    </label>
                                    <input
                                      id="gLastName"
                                      required
                                      type="text"
                                      class="form-control"
                                      name="gLastName"
                                    />
                                  </div>
                                  {error && (
                                    <div className="col-md-12">
                                      <p style={this.errorstyles}>{error}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </InputGroup>
                          </div>
                          <div className="form-row my-3">
                            <InputGroup
                              tag="div"
                              className="form-group col-md-6"
                              validate={["gPhoneNumber"]}
                            >
                              {({ error }) => (
                                <>
                                  <div>
                                    <label htmlFor="gPhoneNumber">
                                      Phone Number{" "}
                                      <span className="text-danger">* </span>
                                    </label>
                                    <input
                                      id="gPhoneNumber"
                                      required
                                      type="text"
                                      className="form-control"
                                      pattern="[0-9.]+"
                                      placeholder="0803 123 4567"
                                      name="gPhoneNumber"
                                    />
                                  </div>
                                  {error && (
                                    <div className="col-md-12">
                                      <p style={this.errorstyles}>{error}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </InputGroup>

                            <InputGroup
                              tag="div"
                              validate={["gEmail"]}
                              className="form-group col-md-6"
                            >
                              {({ error }) => (
                                <>
                                  <div>
                                    <label htmlFor="gEmail">
                                      Email Address
                                    </label>
                                    <input
                                      id="gEmail"
                                      type="email"
                                      className="form-control"
                                      placeholder="Email"
                                      name="gEmail"
                                    />
                                  </div>
                                  {error && (
                                    <div className="col-md-12">
                                      <p style={this.errorstyles}>{error}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </InputGroup>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    )}
                  </InputGroup>

                  {/* Guarantor Address Section */}
                  <InputGroup tag="div" validate={["gAddress"]}>
                    {({ error, valid }) => (
                      <div className="card">
                        <h6 className="card-header text-success">
                          {" "}
                          Guarantor Address
                        </h6>
                        {/* <div
                          className="card-header d-flex justify-content-between"
                          id="headingFour">
                          <button
                            type="button"
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="true"
                            aria-controls="collapseFour">
                            
                          </button>
                          <button
                            type="button"
                            className="btn btn-dark btn-sm"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="true"
                            aria-controls="collapseFour">
                            Edit
                          </button>
                        </div> */}
                        {/* <div
                          id="collapseFour"
                          className="collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordion"> */}
                        <div className="card-body px-5">
                          {/* <form> */}
                          <div className="form-row my-3">
                            <InputGroup
                              tag="div"
                              className="form-group col-md-6"
                              validate={{ gState: this.validaDropDownList }}
                            >
                              {({ error }) => (
                                <>
                                  <div>
                                    <label htmlFor="gState">State</label>
                                    <select
                                      id="gState"
                                      class="form-control"
                                      name="gState"
                                      onChange={
                                        this.handleStateSelectionGuarantor
                                      }
                                    >
                                      <option selected>Choose...</option>
                                      {this.state.states
                                        ? this.state.states.map((data) => {
                                            return (
                                              <option
                                                key={data.id}
                                                value={data.id}
                                              >
                                                {data.name}
                                              </option>
                                            );
                                          })
                                        : ""}
                                    </select>
                                  </div>
                                  {error && (
                                    <div className="col-md-12">
                                      <p style={this.errorstyles}>{error}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </InputGroup>

                            <InputGroup
                              tag="div"
                              className="form-group col-md-6"
                              validate={{ gLga: this.validaDropDownList }}
                            >
                              {({ error }) => (
                                <>
                                  <div>
                                    <label htmlFor="gLga">LGA</label>
                                    <select
                                      id="gLga"
                                      class="form-control"
                                      name="gLga"
                                    >
                                      <option selected>Choose...</option>
                                      {this.state.guarantorLga
                                        ? this.state.guarantorLga.map(
                                            (data) => {
                                              return (
                                                <option
                                                  key={data.id}
                                                  value={data.id}
                                                >
                                                  {data.name}
                                                </option>
                                              );
                                            }
                                          )
                                        : ""}
                                    </select>
                                  </div>
                                  {error && (
                                    <div className="col-md-12">
                                      <p style={this.errorstyles}>{error}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </InputGroup>
                          </div>

                          <InputGroup
                            validate={["gTown"]}
                            className="form-row my-3"
                          >
                            {({ error }) => (
                              <>
                                <div className="col-md-12">
                                  <label for="inputState">Town/City</label>
                                  <input
                                    required
                                    type="text"
                                    className="form-control"
                                    name="gTown"
                                  />
                                </div>
                                {error && (
                                  <div className="col-md-12">
                                    <p style={this.errorstyles}>{error}</p>
                                  </div>
                                )}
                              </>
                            )}
                          </InputGroup>
                          <InputGroup
                            tag="div"
                            className="form-row"
                            validate={["gAddress"]}
                          >
                            {({ error }) => (
                              <>
                                <div className="col-md-12">
                                  <label htmlFor="gAddress">Address</label>
                                  <input
                                    id="gAddress"
                                    required
                                    type="text"
                                    className="form-control"
                                    name="gAddress"
                                  />
                                </div>
                                {error && (
                                  <div className="col-md-12">
                                    <p style={this.errorstyles}>{error}</p>
                                  </div>
                                )}
                              </>
                            )}
                          </InputGroup>
                        </div>
                        {/* </div> */}
                      </div>
                    )}
                  </InputGroup>
                  <br />
                  <div className="row">
                    <div className="col-md-3">
                      <button type="submit" className="btn btn-success px-5">
                        Submit
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
